import React, { useEffect } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import {
  AndGroupedCondition,
  AtomicCondition,
  Condition,
  ExamField,
  ExamFieldWithOptions,
  ExamLabel,
  FieldChangeEventHandler,
  IOrientable,
  OrGroupedCondition,
  UIItem,
  UIItemWithChildren,
} from './types';

type RenderChildrenProps = {
  parentName: string;
  items: UIItem[];
  formValues: Record<string, any>;
  setFormValue: (name: string, value: any) => void;
  onFieldChange: FieldChangeEventHandler;
};

type RenderFieldProps = {
  name: string;
  field: any; //ExamField;
  formValues: Record<string, any>;
  setFormValue: (name: string, value: any) => void;
  onFieldChange: FieldChangeEventHandler;
};

const RenderField: React.FC<RenderFieldProps> = ({
  name,
  field,
  formValues,
  setFormValue,
  onFieldChange,
}) => {
  const isDisabled = isUIItemDisabled(field, formValues);
  const isVisible = isUIItemVisible(field, formValues);

  // Sets the default value of the field
  useEffect(() => {
    if (isLabel(field) || !['', undefined, null].includes(formValues[name]))
      return;

    setFormValue(
      name,
      field.default_value ?? (field.type === 'checkbox' ? false : ''),
    );
  }, []);

  if (!isVisible) {
    return null;
  }

  if (field.field_type === 'checkbox') {
    return (
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            onChange={(e) => onFieldChange(e, field)}
            disabled={isDisabled}
            checked={formValues[name] || false}
          />
        }
        label={field.label}
        style={{ opacity: isDisabled ? 0.5 : 1 }}
      />
    );
  }

  if (field.field_type === 'number') {
    return (
      <FormControl>
        <div className="flex items-center gap-2">
          <TextField
            name={name}
            type="number"
            onChange={(e) => onFieldChange(e as any, field)}
            value={formValues[name] || ''}
            disabled={isDisabled}
            style={{
              opacity: isDisabled ? 0.5 : 1,
              width: field.width ?? 'auto',
            }}
            label={field.label}
          />

          {field.post_label && (
            <span style={{ opacity: isDisabled ? 0.5 : 1 }}>
              {field.post_label}
            </span>
          )}
        </div>
      </FormControl>
    );
  }

  if (isSelectField(field)) {
    return (
      <FormControl
        disabled={isDisabled}
        style={{ opacity: isDisabled ? 0.5 : 1 }}
      >
        <InputLabel id={name}>{field.label}</InputLabel>
        <Select
          name={name}
          labelId={name}
          onChange={(e) => onFieldChange(e as any, field)}
          label={field.label}
          sx={{ minWidth: 200 }}
          disabled={isDisabled}
          value={formValues[name] || ''}
        >
          {field.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  if (isRadioGroup(field)) {
    return (
      <FormControl
        component="fieldset"
        disabled={isDisabled}
        style={{ opacity: isDisabled ? 0.5 : 1 }}
      >
        <FormLabel component="legend">{field.label}</FormLabel>
        <RadioGroup
          name={name}
          onChange={(e) => onFieldChange(e, field)}
          style={{
            display: 'flex',
            flexDirection: field.direction === 'horizontal' ? 'row' : 'column',
          }}
        >
          {field.options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
              checked={
                (formValues[name] ?? field.default_value) === option.value
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }

  if (isLabel(field)) {
    return <div style={{ opacity: isDisabled ? 0.3 : 1 }}>{field.text}</div>;
  }

  if (isHeading(field)) {
    return (
      <h2
        style={{ opacity: isDisabled ? 0.3 : 1 }}
        className="mb-2 mt-4 text-xl font-semibold"
      >
        {field.text}
      </h2>
    );
  }

  return null;
};

export const isUIItemDisabled = (
  item: ExamField | ExamLabel,
  formValues: Record<string, any>,
) => {
  return item.disabled_if
    ? checkFieldCondition(item.disabled_if, formValues)
    : false;
};

export const isUIItemVisible = (
  item: ExamField | ExamLabel,
  formValues: Record<string, any>,
) => {
  return item.visible_if
    ? checkFieldCondition(item.visible_if, formValues)
    : true;
};

export const RenderChildren: React.FC<RenderChildrenProps> = ({
  parentName,
  items,
  formValues,
  setFormValue,
  onFieldChange,
}) => {
  return (
    <>
      {items.map((item, i) => {
        const itemName = item.name ? `${parentName}.${item.name}` : parentName;

        if (isField(item)) {
          return (
            <RenderField
              key={itemName}
              name={itemName}
              field={item}
              formValues={formValues}
              setFormValue={setFormValue}
              onFieldChange={onFieldChange}
            />
          );
        }

        if (isRow(item)) {
          return (
            <div key={i} className="flex flex-nowrap items-center gap-10">
              <RenderChildren
                parentName={itemName}
                items={item.children}
                formValues={formValues}
                setFormValue={setFormValue}
                onFieldChange={onFieldChange}
              />
            </div>
          );
        }

        return null;
      })}
    </>
  );
};

export function checkFieldCondition(
  condition: Condition,
  formValues: Record<string, any>,
) {
  const conditions = isAtomicCondition(condition)
    ? [condition]
    : isOrGroupedCondition(condition)
      ? condition.or
      : isAndGroupedCondition(condition)
        ? condition.and
        : [];

  return conditions[isOrGroupedCondition(condition) ? 'some' : 'every'](
    (condition) => checkFieldAtomicCondition(condition, formValues),
  );
}

export function checkFieldAtomicCondition(
  condition: AtomicCondition,
  formValues: Record<string, any>,
) {
  const fieldValue = formValues[condition.field];

  if (condition.isFalsy !== undefined) {
    return !fieldValue === condition.isFalsy;
  }

  if (condition.isTruthy !== undefined) {
    return !!fieldValue === condition.isTruthy;
  }

  if (typeof condition.value !== undefined) {
    return fieldValue === condition.value;
  }

  console.error('Invalid condition', condition);
}

function isField(item: UIItem): item is ExamField {
  return item.item_type === 'field' || isLabel(item) || isHeading(item);
}

function isLabel(item: UIItem): item is ExamLabel {
  return item.item_type === 'label';
}

function isHeading(item: UIItem): item is ExamLabel {
  return item.item_type === 'heading';
}

function isSelectField(item: UIItem): item is ExamFieldWithOptions {
  return isField(item) && item.field_type === 'select' && 'options' in item;
}

function isRadioGroup(
  item: UIItem,
): item is ExamFieldWithOptions & IOrientable {
  return (
    isField(item) && item.field_type === 'radio_group' && 'options' in item
  );
}

function isRow(item: UIItem): item is UIItemWithChildren {
  return item.item_type === 'row';
}

function isAtomicCondition(condition: Condition): condition is AtomicCondition {
  return (condition as any).field !== undefined;
}

function isOrGroupedCondition(
  condition: Condition,
): condition is OrGroupedCondition {
  return !isAtomicCondition(condition) && Array.isArray((condition as any).or);
}

function isAndGroupedCondition(
  condition: Condition,
): condition is AndGroupedCondition {
  return !isAtomicCondition(condition) && Array.isArray((condition as any).and);
}
