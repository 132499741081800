import { useQuery } from '@tanstack/react-query';

export const useModuleExams = (module?: string) => {
  return useQuery<string[][]>({
    queryKey: ['module-exams', module],
    queryFn: () => fetchModuleExams(module!),
    enabled: !!module,
  });
};

export const fetchModuleExams = async (module: string) => {
  const res = await fetch(
    `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/module/${module}/exams`,
  );
  return res.json();
};
