import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import cn from 'classnames';
import { useParams, useNavigate } from 'react-router-dom';
import { LuMousePointerClick as IconFormMode } from 'react-icons/lu';
import { PiTextTBold as IconTextMode } from 'react-icons/pi';
import { TbReportMedical as ProvisoryLogo } from 'react-icons/tb';
import { useGlobalState } from 'src/hooks/useGlobalState';
import { ExamPicker } from './ExamPicker';
import { useModuleExams } from 'src/hooks/queries/useModuleExams';
import { useEffect } from 'react';

type Props = {
  className?: string;
};

export const Header: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate();
  const { exam_name: examName, module_name: moduleName } = useParams();
  const {
    mode,
    setMode,
    setCurrentExamType,
    setCurrentModule,
    setCurrentModuleExams,
    currentModule,
  } = useGlobalState();
  const { data: moduleExams } = useModuleExams(currentModule);

  const handleExamChange = (examName: string) => {
    setCurrentExamType(examName);
    navigate(`/module/${currentModule}/exam/${examName}`);
  };

  useEffect(() => {
    if (!moduleName || !examName) return;

    setCurrentModule(moduleName);
    setCurrentExamType(examName);
  }, []);

  useEffect(() => {
    if (!moduleExams) return;

    setCurrentModuleExams(moduleExams);
  }, [moduleExams]);

  return (
    <header
      className={cn(
        className,
        'flex h-16 w-full items-center border-b border-gray-800/50 px-4',
      )}
    >
      <h1 className="text-md mr-4 flex items-center gap-2 font-semibold text-blue-400">
        <ProvisoryLogo />
        Laudo Assistido
      </h1>
      {examName && (
        <ExamPicker
          value={examName}
          exams={moduleExams ?? []}
          onChange={({ target }) => handleExamChange(target.value)}
        />
      )}
      <Stack direction="row" spacing={1} className="ml-auto items-center">
        <IconFormMode />
        <Switch
          color="primary"
          checked={mode === 'TEXT'}
          onChange={({ target }) => setMode(target.checked ? 'TEXT' : 'FORM')}
        />
        <IconTextMode />
      </Stack>
    </header>
  );
};
