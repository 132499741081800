import { useExamUI } from 'src/hooks/queries/useExamUI';
import { Sidebar } from './Sidebar';
import { useState } from 'react';
import { Content } from './Content';
import { FieldChangeEventHandler } from 'src/engine/types';

type Props = {
  moduleName: string | undefined;
  examName: string | undefined;
  formValues: Record<string, any>;
  handleFieldChange: FieldChangeEventHandler;
  setFormValue: (name: string, value: any) => void;
};

export const FormMode: React.FC<Props> = ({
  moduleName,
  examName,
  formValues,
  handleFieldChange,
  setFormValue,
}) => {
  const { data: uiStructure } = useExamUI(moduleName, examName);
  const [activeSectionName, setActiveSectionName] = useState<string>('');

  if (!uiStructure) {
    return null;
  }

  const sectionName = activeSectionName || uiStructure.sections[0].name;

  return (
    <div className="flex grow">
      <Sidebar
        activeSectionName={sectionName}
        onActiveSectionChange={setActiveSectionName}
        uiStructure={uiStructure}
        formValues={formValues}
      />
      <Content
        activeSectionName={sectionName}
        uiStructure={uiStructure}
        onActiveSectionChange={setActiveSectionName}
        formValues={formValues}
        setFormValue={setFormValue}
        onFieldChange={handleFieldChange}
      />
    </div>
  );
};
