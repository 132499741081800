import MenuItem from '@mui/material/MenuItem';
import { FormControl, Select, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';

type Props = {
  value: string;
  className?: string;
  exams: string[][];
  onChange: (event: SelectChangeEvent) => void;
};

export const ExamPicker: React.FC<Props> = ({
  value,
  exams,
  className,
  onChange,
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChange?.(event);
  };

  return (
    <FormControl
      sx={{ m: 1, minWidth: 120 }}
      size="small"
      className={className}
    >
      <Select
        labelId="exam-picker-label"
        id="exam-picker"
        value={value}
        onChange={handleChange}
        style={{ fontSize: '0.75rem' }}
      >
        {exams.map(([label, value]) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
