import cn from 'classnames';
import { checkFieldCondition } from 'src/engine/RenderChildren';
import { ExamUIStructure } from 'src/engine/types';

type Props = {
  activeSectionName: string;
  uiStructure: ExamUIStructure;
  formValues: Record<string, any>;
  onActiveSectionChange: (activeSectionName: string) => void;
};

export const Sidebar: React.FC<Props> = ({
  activeSectionName,
  uiStructure,
  formValues,
  onActiveSectionChange,
}) => {
  return (
    <div className="flex min-h-full min-w-96 flex-col border-r border-gray-800/50 p-2 py-6">
      <span className="relative pb-2 pl-6 text-xs font-semibold uppercase tracking-wider text-gray-400 before:absolute before:bottom-0 before:left-[10px] before:top-[7px] before:block before:border-l before:border-gray-800 before:content-[''] after:absolute after:left-[7px] after:top-[5px] after:block after:h-[7px] after:w-[7px] after:rounded-sm after:border after:border-gray-800 after:bg-gray-900 after:content-['']">
        {uiStructure.title}
      </span>
      {uiStructure.sections.map((section: any) => {
        const isVisible = section.visible_if
          ? checkFieldCondition(section.visible_if, formValues)
          : true;

        if (!isVisible) {
          return null;
        }

        return (
          <a
            key={section.name}
            onClick={() => onActiveSectionChange(section.name)}
            className={cn(
              'relative cursor-pointer rounded-md py-1 pl-6 pr-6 text-sm font-semibold text-gray-300 before:absolute before:bottom-0 before:left-[10px] before:top-0 before:block before:border-l before:border-gray-800 before:content-[""] hover:bg-gray-900 hover:text-white',
              {
                '!bg-gray-800 !text-blue-400 before:!border-blue-400':
                  section.name === activeSectionName,
              },
            )}
          >
            {section.title}
          </a>
        );
      })}
    </div>
  );
};
