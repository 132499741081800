import { useParams } from 'react-router-dom';
import { useGlobalState } from 'src/hooks/useGlobalState';
import { FormMode } from './components/FormMode';
import { TextMode } from './components/TextMode';
import { useState } from 'react';
import { useDialog } from 'src/contexts/dialogContext';
import { FieldChangeEventHandler } from 'src/engine/types';

type Props = {};

export const ExamPage: React.FC<Props> = () => {
  const { mode } = useGlobalState();
  const { exam_name: examName, module_name: moduleName } = useParams();
  const [allFormValues, setAllFormValues] = useState<Record<string, any>>({});
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { showConfirmation } = useDialog();

  const handleFieldChange: FieldChangeEventHandler = (e, fieldConfig) => {
    const { name, value, type, checked } = e.target;

    const setValue = () => {
      setFormValue(name, type === 'checkbox' ? checked : value);

      if (!fieldConfig.ignore_dirty_check) {
        setIsDirty(true);
      }
    };

    if (fieldConfig.confirm_change_if_dirty && isDirty) {
      showConfirmation({
        title: 'Tem certeza?',
        text: fieldConfig.confirm_change_if_dirty || '',
        onYes: () => {
          setValue();
          setIsDirty(false);
        },
      });
    } else {
      setValue();
    }
  };

  // TODO: Move this to a context, so we don't have to pass it down to every component
  const setFormValue = (name: string, value: any) => {
    setAllFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const props = {
    moduleName,
    examName,
    formValues: allFormValues,
  };

  return (
    <div className="flex grow">
      {mode === 'FORM' ? (
        <FormMode
          {...props}
          handleFieldChange={handleFieldChange}
          setFormValue={setFormValue}
        />
      ) : (
        <TextMode {...props} />
      )}
    </div>
  );
};
